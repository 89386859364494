import {Box, Button, Drawer} from "@mui/material";
import React, {useEffect} from "react";
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';

type BacktestGradeDrawerProps = {
    isOpen: boolean,
    onClose: () => void
}

export default function BacktestGradeDrawer(props: BacktestGradeDrawerProps) {
    const {isOpen, onClose} = props

    const [isVisible, setIsVisible] = React.useState(isOpen)

    useEffect(() => {
        setIsVisible(isOpen)
    }, [isOpen])

    return <>
        <Drawer
            anchor="right"
            open={isVisible}
            onClose={onClose}
            sx={{
                zIndex: 10000
            }}

            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <Box sx={{width: 500}}>
                <Box display={"flex"} flexDirection={"column"} p={3} gap={1}>
                    <Box display={"flex"} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant={"h5"}>Learn more about Backtest Grades</Typography>
                        <Button onClick={onClose}>
                            <CloseIcon/>
                        </Button>
                    </Box>
                    <Box>
                        <Box marginBottom={2}>
                            <Typography variant={'h6'} style={{color: "#00A1ff"}}>Profitability (40%)</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>Grade is an average of all trade ratios.</Typography>
                        </Box>
                        <Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"green"}>A</Typography>
                                <Typography variant={"body1"}>Greater than 30% profit percentage</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"lightgreen"}>B</Typography>
                                <Typography variant={"body1"}>Between 10% and 30% profit percentage</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"yellow"}>C</Typography>
                                <Typography variant={"body1"}>Between 5% and 10% profit percentage</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"orange"}>D</Typography>
                                <Typography variant={"body1"}>Between 0% and 5% profit percentage</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"red"}>F</Typography>
                                <Typography variant={"body1"}>Less than 0% profit percentage</Typography>
                            </Box>
                        </Box>

                    </Box>
                    <Box>
                        <Box marginBottom={2}>
                            <Typography variant={'h6'} style={{color: "#00A1ff"}}>Absolute Drawdown (22.5%)</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>Grade is an average of all trade ratios.</Typography>
                        </Box>
                        <Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"green"}>A</Typography>
                                <Typography variant={"body1"}>Less than 10% drawdown</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"lightgreen"}>B</Typography>
                                <Typography variant={"body1"}>Between 10% and 20% drawdown</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"yellow"}>C</Typography>
                                <Typography variant={"body1"}>Between 20% and 40% drawdown</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"orange"}>D</Typography>
                                <Typography variant={"body1"}>Between 40% and 60% drawdown</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"red"}>F</Typography>
                                <Typography variant={"body1"}>Greater than 60% drawdown</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box marginBottom={2}>
                            <Typography variant={'h6'} style={{color: "#00A1ff"}}>Relative Drawdown (22.5%)</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>Grade is an average of all trade ratios.</Typography>
                        </Box>
                        <Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"green"}>A</Typography>
                                <Typography variant={"body1"}>Less than 10% drawdown</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"lightgreen"}>B</Typography>
                                <Typography variant={"body1"}>Between 10% and 15% drawdown</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"yellow"}>C</Typography>
                                <Typography variant={"body1"}>Between 15% and 40% drawdown</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"orange"}>D</Typography>
                                <Typography variant={"body1"}>Between 40% and 60% drawdown</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"red"}>F</Typography>
                                <Typography variant={"body1"}>Greater than 60% drawdown</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box marginBottom={2}>
                            <Typography variant={'h6'} style={{color: "#00A1ff"}}>Date Range (10%)</Typography>
                            <Typography variant={"body2"} color={"textSecondary"}>Bonus applied if backtest range included the past 7 days</Typography>
                        </Box>
                        <Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"green"}>A</Typography>
                                <Typography variant={"body1"}>Greater than 3 months</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"lightgreen"}>B</Typography>
                                <Typography variant={"body1"}>Between 1 and 3 months</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"yellow"}>C</Typography>
                                <Typography variant={"body1"}>Less than 1 month</Typography>
                            </Box>
                            {/*<Box display={"flex"} gap={1}>*/}
                            {/*    <Typography variant={"body1"} color={"orange"}>D</Typography>*/}
                            {/*</Box>*/}
                            {/*<Box display={"flex"} gap={1}>*/}
                            {/*    <Typography variant={"body1"} color={"red"}>F</Typography>*/}
                            {/*</Box>*/}
                        </Box>
                    </Box>
                    <Box>
                        <Box marginBottom={2}>
                            <Typography variant={'h6'} style={{color: "#00A1ff"}}>Number of Ratios (5%)</Typography>
                            <Typography variant={"body2"}></Typography>
                        </Box>
                        <Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"green"}>A</Typography>
                                <Typography variant={"body1"}>Greater than 10 ratios</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"lightgreen"}>B</Typography>
                                <Typography variant={"body1"}>Between 5 and 10 ratios</Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <Typography variant={"body1"} color={"yellow"}>C</Typography>
                                <Typography variant={"body1"}>Between 1 and 5 ratios</Typography>
                            </Box>
                            {/*<Box display={"flex"} gap={1}>*/}
                            {/*    <Typography variant={"body1"} color={"orange"}>D</Typography>*/}
                            {/*</Box>*/}
                            {/*<Box display={"flex"} gap={1}>*/}
                            {/*    <Typography variant={"body1"} color={"red"}>F</Typography>*/}
                            {/*</Box>*/}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Drawer>
    </>
}
